* {
  box-sizing: border-box;
  user-select: none;
  margin: 0;
  padding: 0;
}

html {
  font-family: Rubik, sans-serif;
  /*
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

body {
  background-color: black;
  margin: auto;
}

.game-canvas {
  --focus-filter: drop-shadow(0 calc(4px * var(--scale)) calc(4px * var(--scale)) rgba(0, 0, 0, .4));
  --slight-focus-filter: drop-shadow(0 calc(2px * var(--scale)) calc(2px * var(--scale)) rgba(0, 0, 0, .2));
  --focus-scale: 1.2;
}

button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  margin: 0;
}

button:active {
  /* XXX: Safari hack */
  color: black;
}
